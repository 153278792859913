<script>
import BaseForm from './ActivityBaseForm.vue';
import Land from '../../services/land';
const land = new Land();
export default {
  extends: BaseForm,
  props: {
    update: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.getData();
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.getData();
    },
    getData() {
      this.pageLoading = true;
      land
        .getActivity(this.activityId)
        .then(data => {
          this.formData = data;
          if (typeof this.formData.name === 'string') {
            this.formData.name = [this.formData.name];
          }
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    submit() {
      this.loading = true;
      const type = this.formData.type;
      land
        .updateActivity(this.activityId, this.formData)
        .then(() => {
          const action = {
            feature: 'land',
            action: 'update',
            detail: {
              ...this.formData
            }
          };
          this.$emit('success', action);
        })
        .catch(error => {
          this.error = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    activityId: {
      handler(value) {
        if (value) {
          this.getData();
        }
      }
    }
  }
};
</script>

<style>
</style>
